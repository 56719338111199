'use strict';
jQuery(document).ready(function($){
  // $('.section-one.home .button1').on('click', function(){
  //   $('html, body').animate({
  //     scrollTop: $("#booking-calendar").offset().top -50
  // }, 1000);
  // });
    $('.home-slider').slick({
        infinite: true,
        autoplay: true,
        fade: true,
        arrows:false,
        dots:true,
        pauseOnHover: false,
        autoplaySpeed:3000
     
      });

      $('.merken-slider').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        rows: 1,
        autoplay:true,
        arrows:false,
        pauseOnHover:false,
        responsive: [
        {
          breakpoint: 769,
          settings: {
          arrows: false,
          slidesToShow: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
          arrows: false,
          slidesToShow: 2
          }
        }
        ]
      });
      
});